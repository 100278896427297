import type { ItemModel, DataItemModel, RecordSourceOptions } from 'o365-dataobject';
import type { DataGridControl } from 'o365-datagrid';

import { DataObject, Item as DataItem, DataHandler } from 'o365-dataobject';
import { VersionedStorage, EventEmitter } from 'o365-modules';
import { logger, Url } from 'o365-utils';

import { NodeItem, type ICalculatedField } from './DataObject.NodeItem.ts';
import { HierarchyLevelConfiguration, type NodeDataHierarchyConfigurationOptions } from './DataObject.Configurations.Hierarchy.ts';
import { GroupByLevelConfiguration, type NodeDataGroupByConfigurationOptions } from './DataObject.Configurations.GroupBy.ts'
import { INodeData } from './types';

Object.defineProperties(DataObject.prototype, {
    'nodeData': {
        get() {
            if (this._nodeData == null) {
                this._nodeData = new NodeData(this);
                this._nodeData.initialize();
            }
            return this._nodeData;
        },
        configurable: true
    },
    'hasNodeData': {
        get() {
            return !!this._nodeData;
        },
        configurable: true
    }
});

export default class NodeData<T extends ItemModel = ItemModel> implements INodeData<T> {

}